import React from 'react';
import {Collage} from "../../components/Collage/styles";
import {Birthdays} from "../../components/CarouselBirthdays";

export const Home = () => {

    return (
        <Collage>
            <Birthdays/>
            {/*<WeddingAnniversary/>*/}
        </Collage>
    );
};
