import { stringify } from 'query-string';

import { IGetUrlBuilder } from './IGetUrlBuilder';
import { RequestParameters } from './IRestClient';

export class GetUrlBuilder implements IGetUrlBuilder {
    build(url: string, requestParameters?: RequestParameters): string {
        const queryParameters = stringify(requestParameters ?? {}, {
            skipNull: true,
            arrayFormat: 'comma',
            skipEmptyString: true,
        });

        if (queryParameters) {
            return `${url}?${queryParameters}`;
        }

        return url;
    }
}