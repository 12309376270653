import styled from 'styled-components';
import {theme} from "../constants";

export const SearchWrapper = styled.div`
    display: flex;
    min-width: 20px;
`;
export const SearchInput = styled.input`
    display: flex;
    outline: 0;
    border-width: 0 0 1px;
    border-color: ${theme.mainColor};
    opacity: 1;
    
    &:placeholder-shown {
        opacity: 0.2;
        &:focus {
            opacity: 1;
        }
    }
`;

export const SearchImg = styled.img`
    object-fit: cover;
    height: 1.3rem;
`;