import React from 'react';
import './App.css';
import {Navbar} from './components/Navbar';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Home} from './pages/home/home';
import {FamilyTreePage} from './pages/family-tree/family-tree';
import {MembersPage} from './pages/members/member';
import {ContactPage} from './pages/contact/contact';
import {ProfilePage} from "./pages/profile/profile";

function App() {
    return (
        <Router>
            <Navbar/>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/members' element={<MembersPage/>}/>
                <Route path='/members/:memberId/profile' element={<ProfilePage/>}/>
                <Route path='/family-tree' element={<FamilyTreePage/>}/>
                <Route path='/contact' element={<ContactPage/>}/>
            </Routes>
        </Router>
    );
}

export default App;
