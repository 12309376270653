import React from 'react';
import {LogoImg, LogoWrapper} from './styles';

export const Logo = () => {
    return (
        <LogoWrapper>
            <LogoImg src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/logo/white/logo-full.png`} alt='Logo'/>
        </LogoWrapper>
    );
};
