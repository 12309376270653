import {HttpMethod} from '../http/HttpMethod';
import {IHttpClient} from '../http/IHttpClient';
import {IRequestSender} from '../request-sender/IRequestSender';

import {IGetUrlBuilder} from './IGetUrlBuilder';
import {ICommandProps, ICommandResult, IRestClient, RequestParameters,} from './IRestClient';
import {FetchHttpClient} from "../http/FetchHttpClient";
import {RequestSender} from "../request-sender/RequestSender";
import {GetUrlBuilder} from "./GetUrlBuilder";
import {HeadersProvider} from "../headers-provider/HeadersProvider";

export class RestClient implements IRestClient {
    constructor(
        private httpClient: IHttpClient = new FetchHttpClient(),
        private requestSender: IRequestSender = new RequestSender(
            new HeadersProvider(),
            process.env.NODE_ENV === "development"
                ? ""
                : "https://api.yesikov.dp.ua"
        ),
        private getUrlBuilder: IGetUrlBuilder = new GetUrlBuilder(),
    ) {
    }

    get<T>(url: string, requestParameters?: RequestParameters): Promise<T> {
        return this.requestSender.sendRequest<T>({
            url: this.getUrlBuilder.build(url, requestParameters),
            method: HttpMethod.GET,
            fetchFunction: this.httpClient.sendRequest.bind(this.httpClient),
        });
    }

    create<TRequest, TResponse>(url: string, request: TRequest, headers?: Record<string, string>): Promise<TResponse> {
        return this.requestSender.sendRequest<TResponse, TRequest>({
            url,
            method: HttpMethod.POST,
            fetchFunction: this.httpClient.sendRequest.bind(this.httpClient),
            headers,
        }, request);
    }

    delete(url: string): Promise<void> {
        return this.requestSender.sendRequest({
            url,
            method: HttpMethod.DELETE,
            fetchFunction: this.httpClient.sendRequestWithoutResponse.bind(this.httpClient),
        });
    }

    update<TRequest, TResponse>(url: string, request: TRequest): Promise<TResponse> {
        return this.requestSender.sendRequest<TResponse, TRequest>({
            url,
            method: HttpMethod.PUT,
            fetchFunction: this.httpClient.sendRequest.bind(this.httpClient),
        }, request);
    }

    updatePartially<TRequest, TResponse>(url: string, request: TRequest): Promise<TResponse> {
        return this.requestSender.sendRequest<TResponse, TRequest>({
            url,
            method: HttpMethod.PATCH,
            fetchFunction: this.httpClient.sendRequest.bind(this.httpClient),
        }, request);
    }

    command<TRequest, TResponse>(commandProps: ICommandProps, request: TRequest): ICommandResult<TResponse> {
        const {url, method} = commandProps;
        const abortController = new AbortController();
        const response = this.requestSender.sendRequest<TResponse, TRequest>({
            url,
            method,
            fetchFunction: this.httpClient.sendRequest.bind(this.httpClient),
            abortController,
        }, request);

        return {
            abortController,
            response,
        };
    }
}