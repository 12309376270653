import {IRestClient} from "../util/rest/IRestClient";
import {RestClient} from "../util/rest/RestClient";
import {IProfileApi} from "./IProfileApi";
import {IProfile} from "./model/IProfile";

export class ProfileApi implements IProfileApi {
    constructor(
        private restClient: IRestClient = new RestClient(),
    ) {
    }

    async updateProfile(profile: IProfile): Promise<IProfile> {
        return this.restClient.updatePartially<IProfile, IProfile>(`/api/v1/profiles`, profile);
    }
}