import {IFamilyTreeApi} from "./IFamilyTreeApi";
import {IFamilyTree} from "./model/IFamilyTree";
import {Include} from "./model/Include";
import {IRestClient} from "../util/rest/IRestClient";
import {RestClient} from "../util/rest/RestClient";

export class FamilyTreeApi implements IFamilyTreeApi {
    constructor(
        private restClient: IRestClient = new RestClient(),
    ) {}


    async createFamilyTree(familyTree: IFamilyTree): Promise<IFamilyTree> {
        return this.restClient.get<IFamilyTree>(`/api/v1/family-trees`);
    }

    async getFamilyTree(familyTreeId: number, includes?: Include[]): Promise<IFamilyTree> {
        return this.restClient.get<IFamilyTree>(`/api/v1/family-trees/${familyTreeId}`, {
            includes
        });
    }

    async getFamilyTrees(includes?: Include[]): Promise<IFamilyTree[]> {
        return this.restClient.get<IFamilyTree[]>(`/api/v1/family-trees`, {
            includes
        });
    }

    async removeFamilyTree(familyTreeId: number): Promise<IFamilyTree> {
        return this.restClient.get<IFamilyTree>(`/api/v1/family-trees${familyTreeId}`);
    }

    async updateFamilyTree(familyTree: IFamilyTree): Promise<IFamilyTree> {
        return this.restClient.get<IFamilyTree>(`/api/v1/family-trees`);
    }
}