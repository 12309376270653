import React from 'react';
import {SearchImg, SearchInput, SearchWrapper} from './styles';

export const Search: React.FC<{ onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void }> = ({onSearch}) => {
    return (
        <SearchWrapper>
            <SearchInput
                type="text"
                onChange={onSearch}
                placeholder='search'
            />
            <SearchImg
                src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/icons/icon-search.png`}
                alt='Search'/>
        </SearchWrapper>
    );
};
