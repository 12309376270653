import {isObject} from 'lodash';

import {IHeadersProvider} from '../headers-provider/IHeadersProvider';
import {ContentType} from '../http/ContentType';
import {FetchBody} from '../http/IHttpClient';
import {IBodyWithContentType, IRequestSender, ISendRequestProps} from './IRequestSender';
import {HeadersProvider} from "../headers-provider/HeadersProvider";
import {Security} from "../security/Security";

const NOT_SERIALIZABLE = [
    Blob,
    ArrayBuffer,
    FormData,
    URLSearchParams,
    ReadableStream,
];

export class RequestSender implements IRequestSender {

    constructor(
        private headersProvider: IHeadersProvider = new HeadersProvider(),
        private host: String = ""
    ) {
    }

    async sendRequest<TResponse, TRequest>(
        {
            url,
            method,
            fetchFunction,
            abortController,
            headers: additionalHeaders,
        }: ISendRequestProps<TResponse>,
        request?: TRequest,
        count = 1,
    ): Promise<TResponse> {
        const accessToken = await Security.getToken()
        const {body, contentType} = this.getBodyWithContentType(request);
        const headers = this.headersProvider.getHeaders(contentType, accessToken, additionalHeaders);

        try {
            return await fetchFunction(
                `${this.host}${url}`,
                method,
                headers,
                body,
                abortController ?? null,
            );
        } catch (error) {
            throw error;
        }
    }

    private isNotSerializable(data: unknown): data is FetchBody {
        return NOT_SERIALIZABLE.some(it => (data instanceof it));
    }

    private getBodyWithContentType(data: unknown): IBodyWithContentType {
        if (data === null || data === undefined) {
            return {
                body: undefined,
                contentType: undefined,
            };
        }

        if (this.isNotSerializable(data)) {
            return {
                body: data,
                contentType: undefined,
            };
        }

        if (isObject(data)) {
            return {
                body: JSON.stringify(data),
                contentType: ContentType.JSON,
            };
        }

        return {
            body: String(data),
            contentType: ContentType.TEXT,
        };
    }
}