import React, {useState} from 'react';
import {EditWrapper, EditImg} from "./styles";

export const Edit: React.FC<{
    onEdit: () => void,
    onSave: () => void,
    onCancel: () => void
}> = ({
          onEdit,
          onSave,
          onCancel
      }) => {

    const [editorMode, setEditorMode] = useState<boolean>(false);

    function edit(){
        onEdit()
        setEditorMode(true)
    }
    function save(){
        onSave()
        setEditorMode(false)
    }
    function cancel(){
        onCancel()
        setEditorMode(false)
    }

    return (
        <EditWrapper>
            <>
                {
                    editorMode
                        ? <>
                            <EditImg
                                onClick={cancel}
                                src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/icons/icon-cancel.png`}
                                alt='Cancel'
                            />
                            <EditImg
                                onClick={save}
                                src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/icons/icon-done.png`}
                                alt='Done'
                            />
                        </>
                        : <>
                            <EditImg
                                onClick={edit}
                                src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/icons/icon-edit.png`}
                                alt='Edit'
                            />
                        </>
                }
            </>
        </EditWrapper>
    );
};
