export const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

export const device = {
    mobileS: `(max-width: ${sizes.mobileS})`,
    mobileM: `(max-width: ${sizes.mobileM})`,
    mobileL: `(max-width: ${sizes.mobileL})`,
    tablet: `(max-width: ${sizes.tablet})`,
    laptop: `(max-width: ${sizes.laptop})`,
    laptopL: `(max-width: ${sizes.laptopL})`,
    desktop: `(max-width: ${sizes.desktop})`,
};

export const theme = {
    mainColor: `#2A4049`,
    secondColor: `#ffffff`,
    thirdColor: `#D2B25F`,
}
export const color = {
    navMain: `${theme.mainColor}`,
    navLink: `${theme.secondColor}`,
    navHamburger: `${theme.secondColor}`,
    navActive: `${theme.thirdColor}`,
    navShadow: `0 3px 20px rgba(0, 0, 0, 0.2)`,
};