import styled from "styled-components";
import {theme} from "../constants";

export const SectionBox = styled.div`
    padding: 0.6rem;
`;

export const Header = styled.div`
    display: flex;
    background-color: ${theme.secondColor};
    font-weight: bold;
    border: 1px solid white;
    border-top-left-radius: 1.5em;
    border-top-right-radius: 1.5em;
    padding: 0.6rem;
    color: ${theme.mainColor};
    text-decoration-color: ${theme.mainColor};
    text-decoration: none;
    list-style: none;
    //justify-content: space-evenly;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
`;

export const Content = styled.div`
  background-color: white;
`;