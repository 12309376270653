import {NavLink as Link} from 'react-router-dom';
import styled from 'styled-components';
import {color, device} from '../constants';

export const Nav = styled.nav`
  background: ${color.navMain};
  box-shadow: ${color.navShadow};
  height: 4rem;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
`;

export const NavMenu = styled.div<{ opened?: boolean; }>`
  display: flex;
  list-style: none;
  width: 88vw;
  padding: 0 0.7vw;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  z-index: 1;
  @media screen and ${device.tablet} {
    position: fixed;
    background: ${color.navMain};
    height: 100vh;
    width: 100%;
    flex-direction: column;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(50px at 90% -10%);
    transition: all 0.3s ease-out;
    pointer-events: none;

    ${props => {
      if (props.opened) {
        return `
        position: absolute;
        clip-path: circle(1350px at 90% -10%);
        -webkit-clip-path: circle(1350px at 90% -10%);
        pointer-events: all;
      `;
      }
    }}
  }
`;

export const NavLink = styled(Link)`
  color: ${color.navLink};
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  
  &.active {
    color: ${color.navActive};
  }
  &:hover {
    //color: ${color.navActive};
  }
  &:before {
    content: "";
    display: block;
    height: 1px;
    width: 0;
    background-color: ${color.navActive};
    position: absolute;
    transition: all ease-in-out 250ms;
    margin-top: 1.55rem;
  }

  &.active:before{
    width: 6rem;
  }
`;

export const NavLinkLogout = styled.div`
  background-color: transparent;
  font-weight: bold;
  border: 1px solid ${color.navLink};
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  margin-left: 2vw;
  cursor: pointer;
  color: ${color.navLink};
  text-decoration: none;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;

  &.active {
    color: ${color.navLink};
    background-color: ${color.navActive};
    border: 1px solid ${color.navActive};
  }

  &:hover {
    color: ${color.navLink};
    background-color: ${color.navActive};
    border: 1px solid ${color.navActive};
    transition: all ease-in-out 250ms;
  }
`;

export const Hamburger = styled.div<{ opened?: boolean; }>`
  display: none;
  
  @media screen and ${device.tablet} {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 1.5rem;
    z-index: 2;
    transition: all 0.7s ease;
  }
`;

export const HamburgerLine = styled.div<{ opened?: boolean; }>`
  width: 30px;
  height: 3px;
  background: ${color.navHamburger};
  margin: 5px;
  transition: all 0.3s ease;
  z-index: 1;
  &:nth-child(1){
    transform: ${(props) => (props.opened ? "rotate(-45deg) translate(-5px,6px)" : "rotate(0)")};
  }
  &:nth-child(2){
    transition: all 0.1s ease;
    transform: ${(props) => (props.opened ? "all 0.7s ease" : "rotate(0)")};
    width: ${(props) => (props.opened ? "0" : "")};
  }
  &:nth-child(3){
    transform: ${(props) => (props.opened ? "rotate(45deg) translate(-5px,-6px)" : "rotate(0)")};
  }
`;