import styled from 'styled-components';

export const EditWrapper = styled.div`
    display: flex;
    min-width: 20px;
`;

export const EditImg = styled.img`
    object-fit: cover;
    height: 1.3rem;
    cursor: pointer;

    &:hover {
        filter: invert(110%);
    }
`;