import styled from 'styled-components';
import {device, sizes, theme} from '../constants';

export const CenteredContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: max-content;
    overflow-y: hidden;
    position: relative;
    width: 100%;
`;

export const Card = styled.div`
    position: relative;
    transition: 0.24s all;
    transform: perspective(0) rotateY(0) translateX(0) scale(1, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 328px;
    height: 452px;
    border-radius: 4px;
    box-shadow: 0 24px 40px 0 rgba(0, 0, 0, 0.06);
    border: 1px solid whitesmoke;
    overflow: hidden;
    cursor: pointer;
    filter: blur(0);
    background: #fff;
    margin: 10px;
`;

export const CardPhoto = styled.div<{ photo: string, isLoading: boolean; }>`
    width: 100%;
    height: 284px;
    background: url(${(props) => (props.photo)}) center;
    background-size: 100%;

    ${props => {
        if (props.isLoading) {
            return `
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.16);
      `;
        }
    }}
`;

export const CardTitle = styled.div`
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    letter-spacing: 2px;
`;

export const Name = styled.div`
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    letter-spacing: 2px;
    color: #D2B25F;
    margin-top: 45px;
`;

export const Info = styled.div`
    font-size: 14px;
    font-weight: 300;
    color: #8A8F94;
    margin-top: 8px;
`;

export const Biography = styled.div`
    position: relative;
    min-width: 308px;
    width: ${sizes.laptop};
    background: #fff;
    margin: 10px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 24px 40px 0 rgba(0, 0, 0, 0.06);
    border: 1px solid whitesmoke;
`;

export const Relatives = styled.div`
    position: relative;
    min-width: 308px;
    width: ${sizes.laptop};
    background: #fff;
    margin: 10px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 24px 40px 0 rgba(0, 0, 0, 0.06);
    border: 1px solid whitesmoke;
`;