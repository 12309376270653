import React, {useState} from 'react';
import {Nav, NavLink, NavLinkLogout, NavMenu, Hamburger, HamburgerLine} from './styles';
import {Logo} from '../Logo';
import {Security} from "../../util/security/Security";

export const Navbar = () => {
    const initialMenuState = false
    const [open, setClick] = useState(initialMenuState);
    const resetMenu = () => setClick(initialMenuState);
    const openMenu = () => setClick(!open);
    const logout = () => {
        Security.logout()
            .then(_ => console.info("Client logged out"))
            .catch(error => console.error("Client logged out failed", error))
    }

    return (
        <>
            <Nav>
                <Logo></Logo>
                <Hamburger onClick={openMenu} opened={open}>
                    <HamburgerLine opened={open}/>
                    <HamburgerLine opened={open}/>
                    <HamburgerLine opened={open}/>
                </Hamburger>
                <NavMenu opened={open}>
                    <NavLink to='/' onClick={resetMenu}>
                        Home
                    </NavLink>
                    <NavLink to='/members' onClick={resetMenu}>
                        Members
                    </NavLink>
                    <NavLink to='/family-tree' onClick={resetMenu}>
                        Family Tree
                    </NavLink>
                    <NavLink to='/contact' onClick={resetMenu}>
                        Contact Us
                    </NavLink>
                    <NavLinkLogout onClick={logout}>
                        Sign out
                    </NavLinkLogout>
                </NavMenu>
            </Nav>
        </>
    );
};