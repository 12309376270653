import { IHeadersProvider } from './IHeadersProvider';

export class HeadersProvider implements IHeadersProvider {
    getHeaders(
        contentType: string | undefined,
        accessToken: string | undefined,
        headers?: Record<string, string>,
    ): Record<string, string> {
        const allHeaders : Record<string, string> = {
            Authorization: "",
            'ACCEPT-LANGUAGE': 'en',
            ...headers,
        }

        if (contentType !== undefined) {
            allHeaders['Content-Type'] = contentType;
        }
        if (accessToken !== undefined) {
            allHeaders["Authorization"] = `Bearer ${accessToken}`;
        }

        return allHeaders
    }
}