import {CREDENTIALS_OMIT, HEADER_CONTENT_TYPE, MODE_CORS} from './constants';
import {ContentType} from './ContentType';
import {HttpMethod} from './HttpMethod';
import {FetchArgs, FetchBody, IHttpClient} from './IHttpClient';

export class FetchHttpClient implements IHttpClient {
    async sendRequest<T>(...args: FetchArgs): Promise<T> {
        const response = await this.fetch(...args);

        return this.parseResponse(response);
    }

    async sendRequestWithoutResponse(...args: FetchArgs): Promise<void> {
        await this.fetch(...args);
    }

    async fetch(
        url: string,
        method: HttpMethod,
        headers: Record<string, string>,
        body: FetchBody,
        abortController: AbortController | null,
    ): Promise<Response> {
        const response = await fetch(url, {
            headers,
            method,
            body,
            mode: MODE_CORS,
            credentials: CREDENTIALS_OMIT,
            signal: abortController?.signal ?? null,
        });

        if (!response.ok) {
            throw await this.parseResponse(response);
        }

        return response;
    }

    async parseResponse<T>(response: Response): Promise<any> {
        const contentType = response.headers.get(HEADER_CONTENT_TYPE);

        if (contentType?.includes(ContentType.JSON)) {
            return response.json();
        }

        if (contentType?.includes(ContentType.OCTET_STREAM)) {
            return response.blob();
        }

        if (contentType?.includes(ContentType.TEXT_ANY)) {
            return response.text();
        }
    }
}